import { inject, InjectionKey } from "vue";
import UserStore from "./UserStore";
import ApiStore from "./ApiStore";

export const UserStoreKey: InjectionKey<ReturnType<typeof UserStore>> = Symbol(
	"UserStore"
);

export const ApiStoreKey: InjectionKey<ReturnType<typeof ApiStore>> = Symbol(
	"ApiStore"
);

export function useUserStore() {
	const userStore = inject(UserStoreKey);
	if (userStore == null) {
		throw new Error("Not provide api client yet");
	}
	return userStore;
}

export function useApiStore() {
	const apiStore = inject(ApiStoreKey);
	if (apiStore == null) {
		throw new Error("Not provide api client yet");
	}
	return apiStore;
}

export { UserStore, ApiStore };
