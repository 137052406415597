
import * as vue from "vue";

interface Options {
	confirmButtonText?: string;
	cancelButtonText?: string;
	showConfirmButton?: boolean;
	showCancelButton?: boolean;
	type?: "info" | "success" | "warning" | "error";
	[key: string]: string | boolean | undefined;
}

export default vue.defineComponent({
	name: "ModalComponent",
	setup: (/*props, { emit }*/) => {
		const isModalShow = vue.ref(false);
		const modalTitle = vue.ref("");
		const modalMessage = vue.ref("");

		const options: Options = {
			type: "info",
			confirmButtonText: "",
			cancelButtonText: "",
			showConfirmButton: false,
			showCancelButton: false
		};
		const optionsAlert: Options = {
			type: "info",
			confirmButtonText: "Ok",
			showConfirmButton: true,
			showCancelButton: false
		};
		const optionsConfirm: Options = {
			type: "info",
			confirmButtonText: "Ok",
			cancelButtonText: "Cancel",
			showConfirmButton: true,
			showCancelButton: true
		};

		const optionsSync = (ops: Options) => {
			Object.keys(options).forEach((key: string) => {
				if (typeof ops[key] !== "undefined") options[key] = ops[key];
			});
		};

		let resolveConfirm = () => {
			isModalShow.value = false;
		};

		let rejectCancel = () => {
			isModalShow.value = false;
		};

		/*
			alert(message) || alert(message, title) || alert(message, title, options) || alert(message, options)
		*/
		const alert = (
			message: string,
			title?: string | Options,
			ops?: Options
		) =>
			new Promise<void>(resolve => {
				optionsSync(optionsAlert);
				modalTitle.value = "Alert";
				modalMessage.value = message;
				if (typeof title === "string") {
					modalTitle.value = title;
				} else if (typeof title === "object") {
					ops = title;
				}
				if (typeof ops === "object") {
					optionsSync(ops);
				}
				resolveConfirm = resolve;
				isModalShow.value = true;
			});

		/*
			confirm(message) || confirm(message, title) || confirm(message, title, options) || confirm(message, options)
		*/
		const confirm = (
			message: string,
			title?: string | Options,
			ops?: Options
		) =>
			new Promise<void>((resolve, reject) => {
				optionsSync(optionsConfirm);
				modalTitle.value = "Confirm";
				modalMessage.value = message;
				if (typeof title === "string") {
					modalTitle.value = title;
				} else if (typeof title === "object") {
					ops = title;
				}
				if (typeof ops === "object") {
					optionsSync(ops);
				}
				resolveConfirm = resolve;
				rejectCancel = reject;
				isModalShow.value = true;
			});

		const onConfirm = () => {
			isModalShow.value = false;
			resolveConfirm();
		};
		const onCancel = () => {
			isModalShow.value = false;
			rejectCancel();
		};

		return {
			modalTitle,
			modalMessage,
			options,
			isModalShow,
			alert,
			confirm,
			onConfirm,
			onCancel
		};
	}
});
