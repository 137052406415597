import * as vue from "vue";

export default function() {
	const state = vue.reactive({
		id: localStorage.getItem("userId") ?? "",
		name: localStorage.getItem("userName") ?? "",
		isOneTime: localStorage.getItem("isOneTime") === "true" ? true : false
	});
	return {
		get id() {
			return state.id;
		},
		get name() {
			return state.name;
		},
		get isOneTime() {
			return state.isOneTime;
		},
		set id(value: string) {
			state.id = value;
			localStorage.setItem("userId", value);
		},
		set name(value: string) {
			state.name = value;
			localStorage.setItem("userName", value);
		},
		set isOneTime(value: boolean) {
			state.isOneTime = value;
			localStorage.setItem("isOneTime", `${value}`);
		}
	};
}
