<template>
	<div class="message-root" v-show="isModalShow">
		<div
			class="message-box"
			v-bind:class="[
				options.type === 'success' ? 'message-box-success' : '',
				options.type === 'warning' ? 'message-box-warning' : '',
				options.type === 'error' ? 'message-box-error' : ''
			]"
		>
			<div class="message-box__header">
				<div class="message-box__title">
					<span>{{ modalTitle }}</span>
				</div>
			</div>
			<div class="message-box__content">
				<div class="message-box__container">
					<div class="message-box__message">
						<p>{{ modalMessage }}</p>
					</div>
				</div>
			</div>
			<div class="message-box__btns">
				<button
					v-show="options.showCancelButton"
					@click="onCancel"
					class="el-button el-button--small"
				>
					{{ options.cancelButtonText }}
				</button>
				<button
					v-show="options.showConfirmButton"
					@click="onConfirm"
					class="el-button el-button--small el-button--primary"
				>
					{{ options.confirmButtonText }}
				</button>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import * as vue from "vue";

interface Options {
	confirmButtonText?: string;
	cancelButtonText?: string;
	showConfirmButton?: boolean;
	showCancelButton?: boolean;
	type?: "info" | "success" | "warning" | "error";
	[key: string]: string | boolean | undefined;
}

export default vue.defineComponent({
	name: "ModalComponent",
	setup: (/*props, { emit }*/) => {
		const isModalShow = vue.ref(false);
		const modalTitle = vue.ref("");
		const modalMessage = vue.ref("");

		const options: Options = {
			type: "info",
			confirmButtonText: "",
			cancelButtonText: "",
			showConfirmButton: false,
			showCancelButton: false
		};
		const optionsAlert: Options = {
			type: "info",
			confirmButtonText: "Ok",
			showConfirmButton: true,
			showCancelButton: false
		};
		const optionsConfirm: Options = {
			type: "info",
			confirmButtonText: "Ok",
			cancelButtonText: "Cancel",
			showConfirmButton: true,
			showCancelButton: true
		};

		const optionsSync = (ops: Options) => {
			Object.keys(options).forEach((key: string) => {
				if (typeof ops[key] !== "undefined") options[key] = ops[key];
			});
		};

		let resolveConfirm = () => {
			isModalShow.value = false;
		};

		let rejectCancel = () => {
			isModalShow.value = false;
		};

		/*
			alert(message) || alert(message, title) || alert(message, title, options) || alert(message, options)
		*/
		const alert = (
			message: string,
			title?: string | Options,
			ops?: Options
		) =>
			new Promise<void>(resolve => {
				optionsSync(optionsAlert);
				modalTitle.value = "Alert";
				modalMessage.value = message;
				if (typeof title === "string") {
					modalTitle.value = title;
				} else if (typeof title === "object") {
					ops = title;
				}
				if (typeof ops === "object") {
					optionsSync(ops);
				}
				resolveConfirm = resolve;
				isModalShow.value = true;
			});

		/*
			confirm(message) || confirm(message, title) || confirm(message, title, options) || confirm(message, options)
		*/
		const confirm = (
			message: string,
			title?: string | Options,
			ops?: Options
		) =>
			new Promise<void>((resolve, reject) => {
				optionsSync(optionsConfirm);
				modalTitle.value = "Confirm";
				modalMessage.value = message;
				if (typeof title === "string") {
					modalTitle.value = title;
				} else if (typeof title === "object") {
					ops = title;
				}
				if (typeof ops === "object") {
					optionsSync(ops);
				}
				resolveConfirm = resolve;
				rejectCancel = reject;
				isModalShow.value = true;
			});

		const onConfirm = () => {
			isModalShow.value = false;
			resolveConfirm();
		};
		const onCancel = () => {
			isModalShow.value = false;
			rejectCancel();
		};

		return {
			modalTitle,
			modalMessage,
			options,
			isModalShow,
			alert,
			confirm,
			onConfirm,
			onCancel
		};
	}
});
</script>

<style scoped>
.message-root {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.4);
}
.message-root:after {
	content: "";
	display: inline-block;
	height: 100%;
	width: 0;
	vertical-align: middle;
}
.message-box {
	display: inline-block;
	width: 420px;
	padding-bottom: 10px;
	vertical-align: middle;
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #ebeef5;
	font-size: 18px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	text-align: left;
	overflow: hidden;
	backface-visibility: hidden;
}
.message-box__header {
	position: relative;
	padding: 15px 15px 10px;
}
.message-box__title {
	padding-left: 0;
	margin-bottom: 0;
	font-size: 18px;
	line-height: 1;
	color: #303133;
}
.message-box__content {
	padding: 10px 15px;
	color: #606266;
	font-size: 14px;
}
.message-box__container {
	position: relative;
}
.message-box__message {
	margin: 0;
}
.message-box__message p {
	margin: 0;
	line-height: 24px;
}
.message-box__btns {
	padding: 5px 15px 0;
	text-align: right;
}

.el-button {
	display: inline-block;
	line-height: 1;
	white-space: nowrap;
	cursor: pointer;
	background: #fff;
	border: 1px solid #dcdfe6;
	color: #606266;
	-webkit-appearance: none;
	text-align: center;
	box-sizing: border-box;
	outline: none;
	margin: 0;
	transition: 0.1s;
	font-weight: 500;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	padding: 12px 20px;
	font-size: 14px;
	border-radius: 4px;
}
.el-button--small {
	padding: 9px 15px;
	font-size: 12px;
	border-radius: 3px;
}
.el-button--primary {
	color: #fff;
	background-color: #409eff;
	border-color: #409eff;
}
.message-box__btns button:nth-child(2) {
	margin-left: 10px;
}

.message-box-success {
	background-color: #f0f9eb;
}
.message-box-warning {
	background-color: #fdf6ec;
}
.message-box-error {
	background-color: #fef0f0;
}
</style>
