import * as types from "../types";
import Client from "../Client";

export function list(client: Client) {
	return client.call<{
		values: types.Content[];
	}>("GET", `/contents/`);
}

export function get(client: Client, code: string) {
	return client.call<{
		code: string;
		url: string;
	}>("GET", `/contents/${code}`);
}
