import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Setup from "../views/Setup.vue";
import Play from "../views/Play.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/setup",
		name: "Setup",
		component: Setup
	},
	{
		path: "/plays/:playId",
		name: "Play",
		component: Play,
		props: route => ({
			playId: route.params.playId
		})
	},
	{
		path: "/about",
		name: "About",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue")
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

export default router;
