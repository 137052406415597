import * as types from "../types";
import Client from "../Client";

export function get(client: Client, playId: string) {
	return client.call<types.Play>("GET", `/plays/${playId}`);
}

export function stop(client: Client, playId: string) {
	return client.call<{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		play: any;
	}>("DELETE", `/plays/${playId}`);
}

export function createToken(client: Client, playId: string, userId: string) {
	return client.call<{
		serverUrl: string;
		token: string;
	}>("POST", `/plays/${playId}/tokens`, JSON.stringify({ userId }));
}

export function joinPlayer(
	client: Client,
	playId: string,
	userId: string,
	userName: string
) {
	return client.call<{}>(
		"POST",
		`/plays/${playId}/players`,
		JSON.stringify({ userId, userName })
	);
}

export function raiseEvent(
	client: Client,
	playId: string,
	type: string,
	param: string | number
) {
	return client.call<{}>(
		"POST",
		`/plays/${playId}/events`,
		JSON.stringify({ type, param })
	);
}
