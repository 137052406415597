import { inject, InjectionKey } from "vue";
import Client from "../../agc/Client";

export const ApiClientSymbol: InjectionKey<Client> = Symbol("api-client");

export function useApiClient() {
	const client = inject(ApiClientSymbol);
	if (client == null) {
		throw new Error("Not provide api client yet");
	}
	return client;
}

export default {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	install(app: any, options: { baseUrl: string; apiKey: string }) {
		const client = new Client(options);
		app.config.globalProperties.$apiClient = client;
		app.provide(ApiClientSymbol, client);
	}
};

declare module "@vue/runtime-core" {
	interface ComponentCustomProperties {
		$apiClient: Client;
	}
}
