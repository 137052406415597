import * as responses from "./responses";

interface Options {
	baseUrl: string;
	apiKey?: string;
}

export default class {
	readonly baseHeaders: { [key: string]: string };

	baseUrl!: string;

	requestId: number;

	constructor(options: Options) {
		this.requestId = 0;
		this.baseHeaders = {
			"Content-Type": "application/json; charset=utf-8"
		};

		this.changeOptions(options);
	}

	isReady() {
		return this.baseUrl !== "";
	}

	changeOptions(options: Options) {
		this.baseUrl = options.baseUrl;
		if (options.apiKey != null) {
			this.baseHeaders["X-API-KEY"] = options.apiKey;
		}
	}

	buildHeaders(headers?: {
		[key: string]: string;
	}): { [key: string]: string } {
		if (headers != null) {
			return {
				...this.baseHeaders,
				...headers
			};
		}
		return {
			...this.baseHeaders
		};
	}

	async call<T>(
		method: string,
		path: string,
		body?: string
	): Promise<responses.BaseResponse<T>> {
		return this.callAny(method, path, body);
	}

	async callAny(method: string, path: string, body?: string) {
		const url = `${this.baseUrl}${path}`;
		const requestId = ++this.requestId;
		if (requestId) {
			// 警告回避用。requestIdはそのうちデバッグで使うかも程度
		}
		const response = await fetch(url, {
			method,
			headers: this.buildHeaders(),
			body
		});
		if (response.status < 200 || response.status >= 300) {
			let json: string | null = null;
			try {
				json = await response.json();
			} catch (error) {
				// console.log(response);
				throw new Error(
					`Can not call ${method} ${url}: ${response.status}, not json response.`
				);
			}
			throw new Error(
				`Can not call ${method} ${url}: ${
					response.status
				}, ${JSON.stringify(json)}`
			);
		}
		return response.json();
	}
}
