import * as types from "../types";
import Client from "../Client";

export function list(client: Client, code: string) {
	return client.call<{
		values: types.Play[];
	}>("GET", `/contents/${code}/plays`);
}

export function create(client: Client, code: string) {
	return client.call<{
		playId: string;
		instanceId: string;
	}>("POST", `/contents/${code}/plays`);
}
