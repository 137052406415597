<template>
	<div ref="root"></div>
</template>

<script lang="ts">
import * as vue from "vue";
import * as agvTypes from "@akashic/akashic-gameview-web";

export default vue.defineComponent({
	name: "HelloAkashic",
	props: {
		width: Number,
		height: Number,
		untrustedFrameUrl: String,
		trustedChildOrigin: RegExp,
		contents: Array as vue.PropType<agvTypes.GameConfig[]>
	},
	setup: (props, { emit }) => {
		const root = vue.ref<HTMLDivElement>();
		let agv: agvTypes.AkashicGameView | null = null;
		const gameContents: agvTypes.GameContent[] = [];
		const addContentToAgv = (content: agvTypes.GameContent) => {
			if (agv == null) throw new Error("Invalid state");
			content.setContentLayout({
				scaleMode: agvTypes.ScaleMode.AspectFit,
				verticalAlignment: agvTypes.VerticalAlignment.Center,
				horizontalAlignment: agvTypes.HorizontalAlignment.Center,
				passthroughEvent: true,
				backgroundColor: "transparent"
			});
			agv.addContent(content);
			content.addErrorListener({
				onError: error => {
					// TODO: implemnet error handling
					console.error(error);
					emit("contentLoadError");
				}
			});
			content.addContentLoadListener({
				onLoad: loadTime => {
					console.log("contentLoaded", loadTime);
					emit("contentLoaded");
				}
			});
		};
		const addContent = (contentConfig: agvTypes.GameConfig) => {
			if (agv == null) {
				throw new Error("AGV not initialized");
			}
			const content = new agvTypes.GameContent(contentConfig);
			gameContents.push(content);
			addContentToAgv(content);
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const sendEvents = (data: any) => {
			gameContents.forEach((gameContent: agvTypes.GameContent) => {
				gameContent.sendEvents([[32, null, "", data]]);
			});
		};
		function regenerate() {
			if (root.value == null) {
				throw new Error("Invalid root value");
			}
			const container = root.value;
			const min = Math.min(container.clientWidth, 480);
			if (agv != null) {
				agv.setViewSize(props.width ?? min, props.height ?? min);
				const size = agv.getViewSize();
				gameContents.forEach(content => {
					content.setContentArea({
						x: 0,
						y: 0,
						width: size.width,
						height: size.height
					});
				});
			} else {
				agv = new agvTypes.AkashicGameView({
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					container,
					width: props.width ?? min,
					height: props.height ?? min,
					untrustedFrameUrl: props.untrustedFrameUrl ?? "dummy",
					trustedChildOrigin: props.trustedChildOrigin ?? /.*/
				});
				gameContents.forEach(content => {
					addContentToAgv(content);
				});
			}
			console.log("regenerated agv", gameContents.length);
		}
		vue.onMounted(() => {
			if (root.value == null) {
				throw new Error("Invalid root value");
			}
			regenerate();
			if (props.contents != null) {
				props.contents.forEach(contentConfig => {
					addContent(contentConfig);
				});
			}
			window.addEventListener("resize", () => {
				regenerate();
			});
			console.log(root, root.value);
		});
		vue.onUnmounted(() => {
			if (agv == null) return;
			agv.destroy();
			console.log("onUnmounted");
		});
		return {
			root,
			addContent,
			sendEvents
		};
	}
});
</script>
