
import * as vue from "vue";
import * as stores from "../stores";
import { useApiClient } from "../plugins/api";

export default vue.defineComponent({
	name: "Home",
	components: {},
	setup: () => {
		const client = useApiClient();
		const apiStore = stores.useApiStore();
		const baseUrl = vue.ref(apiStore.baseUrl);
		const apiKey = vue.ref(apiStore.apiKey);

		vue.watch(baseUrl, newValue => {
			apiStore.baseUrl = newValue;
			client.changeOptions({
				baseUrl: apiStore.baseUrl,
				apiKey: apiStore.apiKey
			});
		});
		vue.watch(apiKey, newValue => {
			apiStore.apiKey = newValue;
			client.changeOptions({
				baseUrl: apiStore.baseUrl,
				apiKey: apiStore.apiKey
			});
		});

		return {
			baseUrl,
			apiKey
		};
	}
});
