
import * as vue from "vue";
import * as utils from "@/utils";
import * as stores from "../stores";
import { useApiClient } from "../plugins/api";
import { api, types as apiTypes } from "../agc";
import { useRouter } from "vue-router";

export default vue.defineComponent({
	name: "Home",
	components: {},
	setup: () => {
		const client = useApiClient();
		const router = useRouter();
		const userStore = stores.useUserStore();
		const contents = vue.ref<apiTypes.Content[]>([{ code: "", url: "" }]);
		const selectedContentUrl = vue.ref("");
		const selectedContent = vue.ref<apiTypes.Content>(contents.value[0]);
		const contentPlays = vue.ref<apiTypes.Play[]>([]);
		const selectedPlayId = vue.ref<string>("");
		const userId = vue.ref(userStore.id);
		const userName = vue.ref(userStore.name);
		const isOneTime = vue.ref(userStore.isOneTime);

		vue.watch(userId, newValue => {
			userStore.id = newValue;
		});
		vue.watch(userName, newValue => {
			userStore.name = newValue;
		});
		vue.watch(isOneTime, newValue => {
			userStore.isOneTime = newValue;
		});

		// ユーザの初期化
		if (userStore.id === "") {
			userId.value = utils.generateTemporyString();
		}
		if (userStore.name === "") {
			userName.value = utils.generateTemporyString();
		}

		api.contents.list(client).then(res => {
			Array.prototype.push.apply(contents.value, res.data.values);
		});
		const onContentChanged = () => {
			const content = contents.value.find(
				c => c.url === selectedContentUrl.value
			);
			if (content == null) {
				throw new Error("invalid content");
			}
			selectedContent.value = content;
		};
		const onSelectedContentChanged = async (content: apiTypes.Content) => {
			selectedPlayId.value = "";
			if (content.code === "") {
				return;
			}
			const contentPlaysRes = await api.contentPlays.list(
				client,
				selectedContent.value.code
			);
			contentPlays.value = contentPlaysRes.data.values;
		};
		vue.watch(selectedContent, onSelectedContentChanged);
		const disabledJoin = vue.computed(() => selectedPlayId.value === "");
		const disabledPlay = vue.computed(
			() => selectedContent.value.code === ""
		);
		const onSubmitJoin = async () => {
			if (selectedPlayId.value === "") {
				throw new Error("Invalid play id");
			}
			console.log("join!");
			router.push({
				name: "Play",
				params: {
					playId: selectedPlayId.value
				}
			});
		};
		const onSubmitPlay = async () => {
			const createResult = await api.contentPlays.create(
				client,
				selectedContent.value.code
			);
			console.log("create!", createResult);
			router.push({
				name: "Play",
				params: {
					playId: createResult.data.playId
				}
			});
		};
		const onSubmitStop = async () => {
			if (selectedPlayId.value === "") {
				throw new Error("Invalid play id");
			}
			const stopResult = await api.plays.stop(
				client,
				selectedPlayId.value
			);
			console.log("stop!", stopResult);
			onSelectedContentChanged(selectedContent.value);
		};

		vue.onMounted(() => {
			if (!client.isReady()) {
				router.push("/setup");
			}
		});

		return {
			onContentChanged,
			contents,
			selectedContentUrl,
			selectedPlayId,
			contentPlays,
			disabledJoin,
			disabledPlay,
			onSubmitJoin,
			onSubmitPlay,
			onSubmitStop,
			userId,
			userName,
			isOneTime
		};
	}
});
