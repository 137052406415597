import { createApp } from "vue";
import App from "@/App.vue";
import router from "./router";
import api from "@/plugins/api";
import * as stores from "@/stores";

const apiStore = stores.ApiStore();

createApp(App)
	.use(router)
	.provide(stores.UserStoreKey, stores.UserStore())
	.provide(stores.ApiStoreKey, apiStore)
	.use(api, {
		baseUrl: apiStore.baseUrl,
		apiKey: apiStore.apiKey
	})
	.mount("#app");
