import * as vue from "vue";

export default function() {
	const state = vue.reactive({
		apiKey: localStorage.getItem("apiKey") ?? "",
		baseUrl: localStorage.getItem("baseUrl") ?? ""
	});
	return {
		get apiKey() {
			return state.apiKey;
		},
		get baseUrl() {
			return state.baseUrl;
		},
		set apiKey(value: string) {
			state.apiKey = value;
			localStorage.setItem("apiKey", value);
		},
		set baseUrl(value: string) {
			state.baseUrl = value;
			localStorage.setItem("baseUrl", `${value}`);
		}
	};
}
