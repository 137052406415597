
import * as vue from "vue";

export default vue.defineComponent({
	name: "LoadingBar",
	props: {
		width: Number,
		height: Number
	},
	setup: props => {
		const widthS = props.width ?? 80;
		const heightS = props.height ?? 80;

		return {
			widthS,
			heightS
		};
	}
});
