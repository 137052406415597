
import * as vue from "vue";
import { useRouter } from "vue-router";
import * as utils from "@/utils";
import * as stores from "../stores";
import * as agvTypes from "@akashic/akashic-gameview";
import { useApiClient } from "../plugins/api";
import { api } from "../agc";
import HelloAkashic from "@/components/HelloAkashic.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import ModalComponent from "@/components/ModalComponent.vue";

export default vue.defineComponent({
	name: "Home",
	components: {
		HelloAkashic,
		LoadingBar,
		ModalComponent
	},
	props: {
		playId: String
	},
	setup: a => {
		if (a.playId == null) {
			throw new Error("error");
		}
		const isLoading = vue.ref(true);

		/*** ModalComponent ***/
		const modal = vue.ref<InstanceType<typeof ModalComponent>>();
		/*** End ModalComponent ***/

		const client = useApiClient();
		const router = useRouter();
		const userStore = stores.useUserStore();
		const raiseEventType = vue.ref("");
		const raiseEventParam = vue.ref("");
		const sendEventInput = vue.ref("");
		const sendEventType = vue.ref("text");
		const agv = vue.ref<InstanceType<typeof HelloAkashic>>();
		const user = userStore.isOneTime
			? {
					id: utils.generateTemporyString(),
					name: utils.generateTemporyString()
			  }
			: {
					id: userStore.id,
					name: userStore.name
			  };
		const contentConfig = vue.reactive({
			contentUrl: "",
			player: {
				id: user.id,
				name: user.name
			},
			playConfig: {
				playId: a.playId,
				executionMode: agvTypes.ExecutionMode.Passive,
				playlogServerUrl: "",
				playToken: "",
				protocol: agvTypes.ProtocolType.WebSocket
			}
		});
		const isPreparing = vue.ref(true);

		const onSubmitEnd = async () => {
			if (a.playId == null) throw new Error("invalid");
			await api.plays.stop(client, a.playId);
			isPreparing.value = true;
		};

		const raiseJoin = () => {
			if (a.playId == null) throw new Error("invalid status");
			return api.plays.joinPlayer(client, a.playId, user.id, user.name);
		};

		const raiseStartStage = () => {
			if (a.playId == null) throw new Error("invalid status");
			const param = parseInt(raiseEventParam.value, 10);
			if (Number.isNaN(param)) {
				console.error("invalid param. only set the number value.");
				return;
			}
			return api.plays.raiseEvent(client, a.playId, "start:stage", param);
		};

		const onSubmitRaiseEvent = () => {
			switch (raiseEventType.value) {
				case "join":
					raiseJoin();
					return;
				case "start:stage":
					raiseStartStage();
					return;
				default:
					return;
			}
		};

		const createEventValue = () => {
			const value = sendEventInput.value;
			switch (sendEventType.value) {
				case "text":
					if (value.trim() === "") {
						throw new Error("Can not send empty text value.");
					}
					return {
						type: "text",
						text: value.trim()
					};
				default:
					throw new Error("Invalid event");
			}
		};

		const onSubmitSendEvent = () => {
			try {
				const value = createEventValue();
				agv.value?.sendEvents(value);
			} catch (error) {
				isLoading.value = false;
				modal.value?.alert(error.message, "Warning!", {
					type: "warning"
				});
			}
		};
		const init = async () => {
			if (a.playId == null) throw new Error("Invalid status");
			const playRes = await api.plays.get(client, a.playId);
			if (
				playRes.data.status !== "running" &&
				playRes.data.status !== "preparing"
			) {
				isLoading.value = false;
				modal.value?.alert("This play is ended.", "Error!", {
					type: "error"
				});
				//alert("this play is ended");
				return;
			}
			const contentRes = await api.contents.get(
				client,
				playRes.data.gameCode
			);
			contentConfig.contentUrl = contentRes.data.url;
			const tokenRes = await api.plays.createToken(
				client,
				a.playId,
				user.id
			);
			contentConfig.playConfig.playlogServerUrl = tokenRes.data.serverUrl;
			contentConfig.playConfig.playToken = tokenRes.data.token;
		};
		init().catch(error => {
			console.error("initialize error", error);
			router.push("/setup");
		});
		vue.watch(contentConfig, config => {
			if (config.playConfig.playToken === "") return;
			if (config.playConfig.playlogServerUrl === "") return;
			if (config.contentUrl === "") return;
			isPreparing.value = false;
			console.log("add content", contentConfig);
			agv.value?.addContent(contentConfig);
		});

		const onContentLoaded = () => {
			isLoading.value = false;
		};

		const onContentLoadError = () => {
			isLoading.value = false;
			modal.value?.alert("Content Load Error !!", "Warning!", {
				type: "warning"
			});
		};

		return {
			agv,
			raiseEventType,
			raiseEventParam,
			onSubmitRaiseEvent,
			sendEventInput,
			sendEventType,
			onSubmitSendEvent,
			user,
			onSubmitEnd,
			isPreparing,
			isLoading,
			onContentLoaded,
			onContentLoadError,
			modal
		};
	}
});
