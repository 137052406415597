<template>
	<div class="loading-root">
		<svg :width="widthS" :height="heightS" class="loading-spinner">
			<image
				xlink:href="/images/fading-lines.svg"
				:width="widthS"
				:height="heightS"
			/>
		</svg>
	</div>
</template>

<script lang="ts">
import * as vue from "vue";

export default vue.defineComponent({
	name: "LoadingBar",
	props: {
		width: Number,
		height: Number
	},
	setup: props => {
		const widthS = props.width ?? 80;
		const heightS = props.height ?? 80;

		return {
			widthS,
			heightS
		};
	}
});
</script>

<style scoped>
.loading-root {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	z-index: 9999;
	background-color: rgba(0, 0, 0, 0.3);
}
.loading-spinner {
	margin: auto;
}
</style>
